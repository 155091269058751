import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Terms & Conditions" />

    <section className="default-top">
      <div className="container">
        <h2>Terms & Conditions</h2>
      </div>
    </section>

    <section className="default-content">
      <div className="container">
        <h4>Terms</h4>
        <p>
          Our site is for businesses only. By using our site, you warrant that
          you are acting as a business and not a consumer. By using our site you
          accept our terms By using our site, you confirm that you accept our
          terms and agree to comply with them. You are also responsible for
          ensuring that all persons who access our site through your internet
          connection are aware of our terms, and that they comply with them. If
          you do not agree to our terms, you must not use our site. We recommend
          that you print a copy of our terms for future reference. Which terms
          apply? We may make changes to our site. We may update and change our
          site from time to time to reflect changes to our services, our users
          needs, applicable regulations and our business priorities.
        </p>
        <h4>Availability</h4>
        <p>
          We do not guarantee that our site, or any content on it, will always
          be available, uninterrupted or error free. We may suspend or withdraw
          or restrict the availability of all or any part of our site for
          business and operational reasons. We will try to give you reasonable
          notice of any suspension or withdrawal. Our site is only for users in
          the UK. Our site is directed to users in the United Kingdom. We do not
          represent that content available on or through our site is appropriate
          for use or available in other locations. You must keep your account
          details safe. If you choose, or you are provided with, a username,
          password or any other piece of information as part of our security
          procedures, you must treat such information as confidential. You must
          not disclose it to any third party. We have the right to disable any
          username or password, whether chosen by you or allocated by us, at any
          time, if in our reasonable opinion you have failed to comply with any
          of the provisions of our terms.
        </p>
      </div>
    </section>
  </Layout>
)

export default TermsAndConditions
